<template>
  <layout-vertical>

    <router-view />
      <!-- Using navbar scoped slot -->
    <div
      slot="navbar"
      class="px-1"
    >
<div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>



    <b-navbar-nav class="nav align-items-center ml-auto">

      <dark-Toggler class="d-none d-lg-block" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
    </div>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'

import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'

import DarkToggler from '../components/DarkToggler.vue'
import UserDropdown from '../components/UserDropdown.vue'



export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
     DarkToggler,
    UserDropdown,
    AppCustomizer,
    LayoutVertical,
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
